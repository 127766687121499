








































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { getDisplay } from "@/DataHelper";
import { showDialog } from "@/UIHelper";
import { onShowSite } from "@/NavigationHelper";
import { Globals } from "@/Globals";
import Constants from "@/Constants";
export declare interface TreeItem {
  bezeichnung: string;
  aic: number;
  stt?: string;
  sttBez?: string;
  children?: TreeItemChild[];
  vonVA?: Date;
}
declare interface TreeItemChild {
  aic: number;
  bezeichnung: string;
  stt: number;
  sttBez: string;
}

@Component
export default class EditHierarchie extends EditComponent {
  @Prop({ default: "BEZEICHNUNG" }) public textKennung!: string;
  @Prop({ default: false }) public fireOnText!: boolean;
  @Prop() public sttVon!: number;
  @Prop() public sttBis!: number;
  @Prop() public selectedItem!: number;
  @Prop() public aicRolle!: number;
  private stt!: number;
  protected items: TreeItem[] = [];
  private fields: object = {
    dataSource: this.items,
    value: "aic",
    text: "bezeichnung",
    child: "children",
  };
  @Prop({ default: false }) public clearable!: boolean;
  @Prop({ default: false }) public noSearch!: boolean;
  private showList: boolean = false;

  protected zeitbereich!: APIZeitbereich | null;
  private list: any[] = [];
  private nullElement: any = {
    aic: null,
    bezeichnung: this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_Bitte_waehlen",
      "Bitte wählen"
    ),
  };
  private showLoading: boolean = false;
  private selected: any = [];
  private selectedElement: any = {
    aic: null,
    bezeichnung: this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_Bitte_waehlen",
      "Bitte wählen"
    ),
    stt: "",
    sttBez: "",
  };
  private field: any = this.queryColumn;
  private errorMessages: string[] = [];
  private showSettings: boolean = false;
  private sysAbfrage: any = null;
  private selectedText: string = "";
  private height: string = "400px";
  private menuwith: string = "80%";
  private initialized: boolean = false;
  private activatable: boolean = true;
  private rawlist: any[] = [];
  private search: string = "";

  public mounted() {
    try {
      this.mountedBase();
      this.showLoading = true;
      if (this.queryZeitbereich) {
        this.zeitbereich = this.queryZeitbereich;
      }
      const me = this;
      if (this.sttVon && this.sttBis) {
        const aicSttVon = this.sttVon;
        const aicSttBis = this.sttBis;
        const aicRolle = this.aicRolle;
        this.$api.getStammtyp(
          (data: any) => me.dataLoadedHierarchie(data),
          aicSttVon,
          null,
          false,
          aicRolle
        );
        this.initialized = true;
      } else if (this.field && this.field.type === "hierarchie") {
        const aicEig = this.field.aicEig;
        const aicSttVon = this.field.meta.Stt;
        this.stt = aicSttVon;
        const begr = this.$globalsStt(Globals.Begriff, this.stt, "", true);
        if (begr) {
          this.sysAbfrage = begr.kennung;
          this.showSettings = true;
        }
        const aicSttBis = this.stammtyp;
        const aicRolle = this.aicRolle;
        this.$api.getHierarchie(
          (data: any) => me.dataLoadedHierarchie(data),
          aicEig,
          aicSttVon,
          aicSttBis,
          aicRolle,
          null,
          false
        );
      } else {
        let name = "" + this.kennung;
        if (this.field) {
          name += " " + this.field.bezeichnung;
        }
        console.log("EditHierarchie: no data and config for " + name);
        this.initialized = true;
      }
    } catch (err) {
      console.log(err);
    }
  }
  private filter(depth: number = 0) {
    const search = this.search.toLowerCase();
    const y = this.items.find((x: any) => {
      if (x.bezeichnung === search) {
        return true;
      }
      if (x.children && depth < 100) {
        const find: any = this.findMe(search, x.children, depth + 1);
        if (find) {
          return true;
        }
      }
    });
    // return y;
  }
  private filterFunction(item: any, queryText: string, itemText: string): boolean {
    queryText = queryText.toLowerCase();
    if (item.headerHtml) {
      return true;
    }
    if (
      (itemText && itemText.toLowerCase && itemText.toLowerCase() + "").startsWith(
        queryText
      )
    ) {
      return true;
    }
    if (item.rowData) {
      const x = item.rowData.find(
        (t: string) => t && t.toLowerCase && (t.toLowerCase() + "").startsWith(queryText)
      );
      if (x) {
        return true;
      }
    }
    return false;
  }

  private dataLoadedHierarchie(data: any) {
    try {
      const valueList = data.data.data;
      if (this.sttVon) {
        this.list = data.data.data;
        const columns = data.data.columns;
        if (columns) {
          this.setColumns(columns);
        }
      } else {
        this.items = data.data.data;
      }
      this.data = this.getInitValue();
      if (this.data) {
        const element = this.data;
        this.list.push(element);
        this.selected = [];
        this.selected.push(element);
        this.stt = element.stt;
        const begr = this.$globalsStt(Globals.Begriff, this.stt, "", true);
        if (begr) {
          this.sysAbfrage = begr.kennung;
          this.showSettings = true;
        }
        if (element.bezeichnung) {
          this.hintText = element.bezeichnung + ", " + element.sttBez;
        }
        if (this.initStichtag) {
          this.showStichtag = true;
          this.hintText = this.hintText + ", ab: " + this.initStichtag;
        }
        this.selectedText = element.bezeichnung;
        this.selectedElement = element;
        this.$emit("input", this.selected);
      } else if (!this.sttVon) {
        this.list = this.items;
      }
      this.$emit("loaded", this.items);
      if (!this.selectedText) {
        this.selectedText = "bitte auswählen";
      }
      if (this.selectedItem) {
        this.selectedValueChanged(this.selectedItem, true);
      }
      this.initialized = true;
      this.showLoading = false;
      return;
    } catch (err) {
      console.log(err);
      this.initialized = true;
      this.showLoading = false;
    }
    this.$emit("loaded", this.items);
    if (!this.selectedText) {
      this.selectedText = "bitte auswählen";
    }
  }
  // @Watch("selected")
  protected selectedValueChanged(data: any, ausInit?: boolean) {
    const isMobile = Capacitor.getPlatform() !== "web";
    if (isMobile) {
      Keyboard.hide();
    }

    let comboVal: any;
    if (this.sttVon) {
      comboVal = data;
    } else {
      comboVal = data[0];
    }
    if (this.sttVon && !this.list) {
      return;
    } else if (!this.sttVon && (!this.items || this.items.length === 0)) {
      return;
    }
    let findSelect;
    if (typeof comboVal === "number") {
      findSelect = this.findMe(comboVal.toString(), this.items, 0);
    } else if (typeof comboVal === "string") {
      findSelect = this.findMe(comboVal, this.items, 0);
    } else if (comboVal && comboVal.aic) {
      findSelect = comboVal;
    } else {
      findSelect = [];
    }
    if (findSelect) {
      this.selected = [];
      this.selected = findSelect;
      this.selectedText = findSelect.bezeichnung;
      this.selectedElement = findSelect;
      if (this.sttVon) {
        this.list.push(findSelect);
      } else {
        this.list = [];
        this.list.push(findSelect);
        const begr = this.$globalsStt(Globals.Begriff, findSelect.stt, "", true);
        if (begr) {
          this.sysAbfrage = begr.kennung;
          this.showSettings = true;
        }
        if (findSelect.bezeichnung) {
          this.hintText = findSelect.bezeichnung + ", " + findSelect.sttBez;
        }
      }
      const val = this.selected;
      if (val == null || (val && val && (this.fireOnText || typeof val !== "string"))) {
        // this.$emit("selected", this.selected);
        this.valueChanged(val, ausInit); // zum event triggern
        this.showList = false;
        // this.$nextTick(() => {
        //   this.$forceUpdate();
        // });
      }
    }
  }
  private findMe(searchterm: string, tosearch: TreeItem[], depth: number = 0) {
    const y = tosearch.find((x: any) => {
      if (x.aic === searchterm) {
        return x;
      }
      if (x.children && depth < 100) {
        const find: any = this.findMe(searchterm, x.children, depth + 1);
        if (find) {
          return find;
        }
      }
    });
    // return y;
  }
  public validate() {
    try {
      if (!this.enabled) {
        this.errorText = "";
        return true;
      } // disabled Controls sind IMMER valide ;)
      if (!this.initialized) {
        // Liste leer also nicht initialisiert...
        return false; // soll eine noch nicht initialisierte Combobox valide sein ?
      }

      if (this.required) {
        if (this.selected && this.selected) {
          this.errorText = "";
          return true;
        }
        this.errorText = this.requiredMessage;
        return false;
      }
      this.errorText = "";
      return true;
    } catch (err) {
      console.error(err);
    }
    return false;
  }

  public getInitValue(): any {
    if (this.column && this.column.meta) {
      if (this.column.meta.neuArt === "Limit" || this.column.meta.neuArt === "Last") {
        return this.getInitValueBase();
      }
    }
    return this.getInitValueBase();
  }
  protected openSettings() {
    const siteName = this.column.title;
    let aic = 0;
    aic = this.selected.aic;
    if (aic === undefined) {
      aic = this.selected[0]?.aic;
    }
    if (Constants.BottomMenuVisible) {
      showDialog({
        title: siteName,
        showTitle: true,
        site: "EditStamm",
        data: {
          query: this.sysAbfrage,
          aic,
          showClose: false,
        },
        fullscreen: true,
        transition: "dialog-bottom-transition",
        onClose: () => {
          return true;
        },
      });
    } else {
      onShowSite({
        title: siteName,
        site: "EditStamm",
        data: {
          query: this.sysAbfrage,
          aic,
          showClose: false,
        },
        callOnLeave: () => {
          return true;
        },
      });
    }
  }
  private setColumns(columns: []) {
    const headerItem: any = {};
    const basePercent: number = 100;
    const visibleColumns: any[] = columns.filter((c: any) => c.meta && !c.meta.invisible);
    let headerHtml = "<span class='ddHeader'>";
    let percent = "";
    const visibleColsWithWidthCount =
      visibleColumns.filter((col: any) => col.meta.webWidth)?.length ?? 0;
    const visibleColumnsLength = visibleColumns.length;

    if (visibleColumnsLength !== visibleColsWithWidthCount) {
      percent = (basePercent / (columns.length - visibleColsWithWidthCount)).toFixed(0);
    } else {
      percent = (basePercent / visibleColsWithWidthCount).toFixed(0);
    }
    for (let i = 0; i < visibleColumns.length; i++) {
      headerHtml +=
        "<span style='display: table-cell;width:" +
        percent +
        "%'>" +
        visibleColumns[i].title +
        "</span>";

      headerItem[visibleColumns[i].name] = {
        aic: -1,
        bezeichnung: visibleColumns[i].title,
      };
    }

    headerItem.headerHtml = headerHtml;
    let bezCol: any = columns.find((c: any) => c.kennung === this.textKennung);
    if (!bezCol) {
      bezCol = columns.find((c: any) => c.datatype === "Stringx");
    }
    const columnBez = bezCol?.name;

    const combo = this.list;
    if (combo.length > 0) {
      const mapped = combo.map((row: any): any => {
        const rowData = [];
        for (let i = 0; i < visibleColumns.length; i++) {
          const column = visibleColumns[i];
          rowData.push(getDisplay(row, column));
        }
        return {
          aic: row.aic_Stamm,
          bezeichnung: row[columnBez],
          row,
          visibleColumns,
          percent,
          rowData,
        };
      });
      if (visibleColumns.length > 1) {
        mapped.unshift(headerItem);
      }
      this.rawlist = mapped;
      this.list = this.rawlist;
    }
  }
}
