



























































































































































































































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import Constants from "@/Constants";
import { Globals } from "@/Globals";
import { showPDF } from "@/PdfHelper";
import { formatDate } from "@/editor/CalenderHelper";
import { showSnackbar, showDialog } from "@/UIHelper";

@Component
export default class Druck extends EditPage {
  private printItem!: ALLDruck;
  private pickerMulti: string[] = [new Date().toISOString().substr(0, 7)];
  private picker: string = new Date().toISOString().substr(0, 7);
  private startVal: any = this.$api.zeitbereich.von;
  private endVal: any = this.$api.zeitbereich.bis;
  private landscape: boolean = true;
  private multiple: boolean = false;
  private multipleSelect: boolean = false;
  private type: string = "month";
  protected printZeitbereich: any;
  private farbe: boolean = false;
  private umbruch: boolean = false;
  private nurSumme: boolean = false;
  private keineSumme: boolean = false;
  private gruppiert: boolean = false;
  private periodisch: boolean = false;
  private eMail: boolean = false;
  private pdfSave: boolean = false;
  private gruppiertDisabled: boolean = false;
  // private minDate?: Date;
  private maxDate: string = "";
  private printLayout: any[] = [];
  private selectedLayout: string = "";
  private zeitFormat: boolean = false;
  private vectorPlanung: any[] = [];
  private ausPlanung: boolean = false;
  private ausPlanungVG: boolean = false;
  private loader: any = null;
  private loading: boolean = false;
  private persDruck: boolean = false;
  private showSchicht: boolean = false;
  private noSelect: boolean = false; // auswahl monate soll ausgeschaltet werden können
  private showNurSumme: boolean = false;
  private showkeineSumme: boolean = false;
  private showButton: boolean = false;
  private selectAll: boolean = false;
  private keinZR: boolean = false;
  public mounted() {
    this.vecStamm = [];
    this.vectorPlanung = [];
    this.mountedBase();
    if (this.siteData.printItem) {
      this.printItem = this.siteData.printItem;
      this.farbe = this.printItem.farbe;
      this.gruppiertDisabled = this.printItem.keineGruppierung;
      this.umbruch = this.printItem.seitenUmbruch;
      const checkType = this.printItem.periode;
      this.showSchicht = this.printItem.schicht;
      this.showNurSumme = this.printItem.nurSumme;
      this.showkeineSumme = this.printItem.keineSumme;
      this.periodisch = this.printItem.abschnittPeriode;
      this.keinZR = this.printItem.keinZR ?? false;
      this.addLayout();
      if (checkType === "offen") {
        if (this.siteData.zeitbereich && this.siteData.zeitbereich.bereich) {
          if (this.siteData.zeitbereich.bereich === "Monat") {
            this.type = "month";
          }
        }
        if (this.siteData.zeitbereich.bereich === "Woche") {
          this.type = "week";
        } else if (this.siteData.zeitbereich.bereich === "Tag") {
          this.type = "day";
        } else {
          this.type = "month";
        }
      } else if (checkType === "Jahr") {
        this.type = "year";
      } else if (checkType === "Monat") {
        this.type = "month";
      }
    }
    if (this.siteData.aic) {
      this.queryAIC = this.siteData.aic;
    }
    if (this.siteData.vecStamm) {
      this.vecStamm = this.siteData.vecStamm;
    }
    if (this.siteData.vectorPlanung) {
      this.vecStamm = [];
      this.vectorPlanung = this.siteData.vectorPlanung;
      this.ausPlanung = this.siteData.ausPlanung;
      if (this.vectorPlanung) {
        for (let i = 0; i < this.vectorPlanung.length; i++) {
          const row = this.vectorPlanung[i];
          this.vectorPlanung[i].select = false;
        }
      }
      this.ausPlanungVG = this.siteData.ausPlanungVG;
    }
    if (this.siteData.pers) {
      this.persDruck = true;
      this.multipleSelect = false;
    }
    if (this.siteData.zeitbereich) {
      const pickDate = this.siteData.zeitbereich.von;
      this.pickerMulti = [new Date(pickDate).toISOStringWithTZ().substr(0, 7)];
      this.picker = new Date(pickDate).toISOStringWithTZ().substr(0, 7);
    }
    if (this.siteData.noSelect) {
      this.noSelect = this.siteData.noSelect;
      let bisDate = "";
      const vonDate = new Date(this.siteData.zeitbereich.von).toISOStringWithTZ();
      if (this.siteData.zeitbereich) {
        bisDate = new Date(this.siteData.zeitbereich.bis).toISOStringWithTZ();
      } else {
        bisDate = new Date(new Date(vonDate).lastInMonth()).toISOStringWithTZ();
      }
      let bereich = "Tag";
      if (this.siteData?.zeitbereich?.bereich) {
        bereich = this.siteData.zeitbereich.bereich;
      }
      if (this.type === "month") {
        bereich = "Monat";
      } else if (this.type === "year") {
        bereich = "Jahr";
      } else if (this.type === "week") {
        bereich = "Woche";
      }
      this.printZeitbereich = {
        bereich,
        von: vonDate,
        bis: bisDate,
      };
    }
    if (Constants.isMobile) {
      this.landscape = false;
    }
    const lastDay = new Date(new Date().getFullYear(), 11, 31);
    const stringDate = formatDate(lastDay, "yyyy-MM-dd");
    if (stringDate) {
      this.maxDate = stringDate;
    }
    if (this.$api.user.zeitFormat === "min") {
      this.zeitFormat = true;
    }
  }
  private selectChange(val: any) {
    const select = val.select;
    this.showButton = false;
    let id = 0;
    if (this.ausPlanung) {
      id = val.id;
      if (select) {
        this.vecStamm.push(id);
      } else {
        this.vecStamm = this.vecStamm.filter((c: any) => c === id);
      }
    } else {
      id = val.GroupID;
      if (select) {
        this.vecStamm.push(id);
      } else {
        this.vecStamm = this.vecStamm.filter((c: any) => c === id);
      }
    }
    if (this.vecStamm.length > 0) {
      this.showButton = true;
    }
  }
  private onPrintItemClick(fromDialog: boolean = false, tabUbergabe: any[] = []) {
    this.loading = true;
    const zeitraum: any[] = [];
    let currentPrintzeitbereich = this.printZeitbereich;
    if (this.multipleSelect === true && this.periodisch) {
      if (this.pickerMulti?.length > 0) {
        for (let i = 0; i < this.pickerMulti.length; i++) {
          const month = this.pickerMulti[i];
          const von = new Date(month).toISOStringWithTZGMT();
          const bis = new Date(von).lastInMonth().toISOStringWithTZGMT();
          zeitraum.push({ von, bis });
        }
      }
    } else if (this.multipleSelect === true && !this.periodisch) {
      if (this.pickerMulti?.length > 0) {
        // es soll der erste und der letzte Monat als Zeitbereich gesetzt werden
        // warum bleibt month leer??

        const vals = this.pickerMulti.map((d) => new Date(d).valueOf());

        const von = new Date(Math.min(...vals)).toISOStringWithTZGMT();
        const bis = new Date(Math.max(...vals)).lastInMonth().toISOStringWithTZGMT();
        let bereich = "Tag";
        if (this.type === "month") {
          bereich = "Monat";
        } else if (this.type === "year") {
          bereich = "Jahr";
        }
        currentPrintzeitbereich = {
          bereich,
          von,
          bis,
        };
      }
    }
    if (!currentPrintzeitbereich) {
      const zeitbereichVon = this.$api.zeitbereich.von;
      const zeitbereichBis = this.$api.zeitbereich.bis;
      let bereich = "Tag";
      if (this.type === "month") {
        bereich = "Monat";
      } else if (this.type === "year") {
        bereich = "Jahr";
      }
      const vonDate = new Date(zeitbereichVon).toISOStringWithTZ();
      const bisDate = new Date(
        new Date(zeitbereichBis).lastInMonth()
      ).toISOStringWithTZ();
      currentPrintzeitbereich = {
        bereich,
        von: vonDate,
        bis: bisDate,
      };
    }
    let begriff: any;
    if (this.printItem) {
      begriff = this.$globalsKennung(Globals.Begriff, this.printItem.kennung);
    }
    if (!this.queryAIC) {
      this.queryAIC = this.$api.user.aic;
    }
    const jokerStt = [];
    jokerStt.push({
      aic: 0, // aic Stammtyp
      sync: this.queryAIC, // aic Stammsatz
    });
    const me = this;
    if (begriff.abfrage && fromDialog !== true) {
      showDialog({
        title: this.$globalsBezeichnung(
          this.Globals.Begriff,
          "Web_Bitte_waehlen",
          "Bitte wählen"
        ),
        width: 250,
        site: "ModellDialog",
        titleColor: "dvhBackground",
        titleClass: "white--text",
        data: {
          zeitbereich: this.zeitbereich,
          query: begriff.abfrage,
          aic: this.queryAIC,
        },
        okButton: true,
        onClose: (x: any) => {
          if (x.returnedData.length > 0) {
            tabUbergabe = x.returnedData;
          }
          if (x.dialogResult === true) {
            me.onPrintItemClick(true, tabUbergabe);
          } else {
            this.loading = false;
          }
          return true;
        },
      });
      return; // nicht weitermachen nur dialog aufmachen}
    }
    this.$api
      .print(
        this.queryAIC,
        this.printItem.aic,
        currentPrintzeitbereich,
        this.farbe,
        this.umbruch,
        this.selectedLayout,
        this.nurSumme,
        this.keineSumme,
        this.gruppiert,
        this.zeitFormat,
        this.vecStamm,
        tabUbergabe,
        zeitraum,
        this.eMail,
        this.pdfSave,
        jokerStt
      )
      .then((res: any) => {
        const resp = res.data.file;
        const info = res.data.error;
        const infoMail = res.data?.EMail;
        let mailError;
        if (infoMail) {
          mailError = res.data?.EMail[0]?.error;
        }
        // const mailError = res.data?.EMail[0]?.error;
        const infoPdf = res.data?.indDB;
        // const pdfError = res.data?.TabFehler;
        // const infoTable = [];
        // for (let i = 0; i < pdfError.length; i++) {
        //   const row = pdfError[i];
        //   infoTable.push(row);
        // }
        // const textTable = infoTable.join("<br>");
        // if (info) {
        //   showSnackbar({
        //     text: info,
        //     color: "error",
        //   });
        // } else
        if (infoMail) {
          if (mailError) {
            showSnackbar({
              text: mailError,
              color: "error",
            });
          } else {
            const text =
              "Email wurde versendet an: " + infoMail[0].adresse + " " + infoMail[0].name;
            showSnackbar({
              text,
              color: "error",
            });
          }
        } else if (infoPdf) {
          showSnackbar({
            text: infoPdf,
            color: "info",
          });
        } else if (info) {
          showSnackbar({
            text: info,
            color: "error",
          });
        } else {
          this.onShowPdf(resp);
        }
        this.loading = false;
      });
  }
  private onShowPdf(pdfFile: any) {
    // const url = this.$api.fullUrl("pdf3/" + pdfFile, "", true);
    showPDF(pdfFile);
    // const res = window.open(url);
  }
  private selectedValueChanged(args: any) {
    this.selectedLayout = args;
  }
  private valueChanged(args: any) {
    if (!args) {
      return;
    }
    let bereich = "Tag";
    if (this.type === "month") {
      bereich = "Monat";
    } else if (this.type === "year") {
      bereich = "Jahr";
    }
    const vonDate = new Date(args).toISOStringWithTZ();
    const bisDate = new Date(new Date(vonDate).lastInMonth()).toISOStringWithTZ();

    this.printZeitbereich = {
      bereich,
      von: vonDate,
      bis: bisDate,
    };
  }
  private addLayout() {
    this.printLayout = this.$globalsLayout("Begriff");
  }
  private setMultiselect(value: boolean) {
    this.multipleSelect = value;
  }
  private selectChangeAll(data: any) {
    if (data) {
      if (this.vectorPlanung) {
        this.vecStamm = [];
        for (let i = 0; i < this.vectorPlanung.length; i++) {
          const row = this.vectorPlanung[i];
          this.vecStamm.push(row.aic ?? row.id ?? row.GroupID);
          this.vectorPlanung[i].select = true;
        }
      }
    } else {
      this.vecStamm = [];
      if (this.vectorPlanung) {
        for (let i = 0; i < this.vectorPlanung.length; i++) {
          const row = this.vectorPlanung[i];
          this.vectorPlanung[i].select = false;
        }
      }
    }
    if (this.vecStamm.length > 0) {
      this.showButton = true;
    } else {
      this.showButton = false;
    }
    // this.$emit("vector", this.vecStamm);
  }
}
