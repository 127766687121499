























































































































import { Vue, Component } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import EditTextArea from "@/editor/EditTextArea.vue";

@Component
export default class EditHotline extends EditPage {
  constructor() {
    super();
    this.query = "Web_DVH_Anlage_Hotline"; // Definiert die Abfrage für diese Seite
  }

  public disabled: boolean = false; // Deaktiviert die Eingabefelder, falls true
  public clearable: boolean = false; // Ermöglicht das Löschen des Eingabefeldinhalts, falls aktiviert
  private deleteVisible: boolean = false; // Kontrolliert die Sichtbarkeit der Löschen-Schaltfläche

  public mounted() {
    this.showLoading = true;
    this.mountedBase(); // Initialisiere die Basisfunktionalitäten der geerbten EditPage-Komponente
    if (this.data && this.data.aic_Bew_pool) {
      this.deleteVisible = true; // Setzt die Löschen-Schaltfläche auf sichtbar, wenn bestimmte Daten vorhanden sind
    }
  }

  public bewDelete() {
    if (this.data && this.data.aic_Bew_pool) {
      this.onDelete(this.data.aic_Bew_pool, ""); // Führt die Löschmethode aus
      this.$emit("back"); // Emittiert das 'back'-Ereignis, um zur vorherigen Ansicht zurückzukehren
    }
  }
}
