
declare var ALLVersion: ALLVersionType;

import AUApi from "./api";
import { downloadNativeUpdate } from "@/NativeUpdateProvider";
import Constants from "./Constants";
import { Capacitor, Plugins } from "@capacitor/core";
import { showSnackbar } from "./UIHelper";
const { FileHelperPlugin } = Plugins;
import { App } from '@capacitor/app';

export interface VersionInfo {
    webVersion: string;
    warVersion: string;
    appVersion: string;
    url?: string;
    AllVersion?: string;
}

let versionCallback: () => void | undefined;
export function setUpdateVersionCallback(callback: () => void) { versionCallback = callback; }
export const curentALLVersion: VersionInfo = { webVersion: "??", warVersion: "??", appVersion: "??" };

export async function CheckVersion(): Promise<VersionInfo> {
    const currentPlattform =  Capacitor.getPlatform();
    try {
        curentALLVersion.webVersion = ALLVersion.version;
        const r = await AUApi.Current.getVersion(); // Version abrufen
        let warVersion = "UNKNOWN";
        let appVersionServer = "UNKOWN";

        const vdata = r?.data as VersionInfo;
        console.log('VersionInfo from Server: ' + JSON.stringify(vdata));
        if (vdata) {
            warVersion = vdata.warVersion;
            appVersionServer = vdata.appVersion;
            const webVersionServer = vdata.webVersion;
            // if (versionCallback) { versionCallback(); }
            curentALLVersion.warVersion = warVersion;
            if (currentPlattform !== "web" && !AUApi.Current.getCurrentConfig().debug) {
                // const webVersionServer = vdata.webVersion;
                const pfad = vdata.url;
                if (pfad) {
                    const datei = pfad + "/downloads/download-app.html";
                    console.log("dateiname:" + datei);
                    const info = await App.getInfo();
                    const appVersion = info?.version ?? "2.0.fallback";
                    console.log("appVersion Device" + appVersion);
                    console.log("appVersion Server" + appVersionServer);
                    if (appVersionServer && appVersion < appVersionServer) {
                        const appC = appVersion.includes("2.");
                        const appS = appVersionServer.includes("3.");
                        let text = "Es ist eine neue AppVersion verfügbar! Bitte aktualisiern Sie diese! (" +
                            appVersionServer +
                            " Client: " + appVersion + ")";
                        if (appC && appS) {
                            text = "Es ist eine zwingende neue AppVersion verfügbar! Bitte gehen Sie in den Store und aktualisiern Sie diese! (" +
                                appVersionServer +
                                " Client: " + appVersion + ")";


                            alert(text);
                            if (currentPlattform === "ios") {
                                window.open("https://apps.apple.com/at/app/all-unlimited/id1555265479");
                            } else {
                                window.open("https://play.google.com/store/apps/details?id=com.dvh.allunlimited");
                            }

                        }
                        // nur wenn die Nummer KLEINER ist - dann soll die Meldung kommen!
                        showSnackbar({
                            text,
                            duration: -1,
                            color: "error",
                            isMessage: false,
                            onClose: (sb) => {
                                // if (sb.closedManual) { FileHelperPlugin.openLinkExternal({ url: datei }); }
                                // download aus Kunden Verzeichnis durch Store nicht nötig!
                            }
                        });
                    }
                    // bei der Demo nicht updaten.
                    const demoDB = AUApi.Current.getCurrentConfig().rootUrl;
                    const demo = "https://demo1.allunlimited.com/ALL/UNLIMITED/";
                    let makeUpdate = true;
                    if (demoDB === demo) {
                        makeUpdate = false;
                    }
                    if (webVersionServer && ALLVersion.version !== webVersionServer
                        && makeUpdate) {
                        // nur wenn Version am Client KLEINER ist - Updaten - im DEBUG Modus!!
                        let rootUrl = AUApi.Current.rootUrl;
                        rootUrl = rootUrl.substring(0, rootUrl.indexOf("/", 10));
                        const updatezip = rootUrl + "/update.zip";

                        await downloadNativeUpdate(updatezip).catch(e => console.warn("Fehler:" + e)); // datei);
                        showSnackbar({
                            text: "Web-Version stimmt nicht überein Update gestartet!: \r\nServer: " +
                                webVersionServer +
                                " Client: " + ALLVersion.version,
                            duration: -1,
                            color: "info",
                            isMessage: false,
                            onClose: (sb) => {
                                // manuell soll es auch immer möglich sein!
                                // if (sb.closedManual) { FileHelperPlugin.openLinkExternal({ url: datei }); }
                                // downloadNativeUpdate("update.zip");// datei);
                            }
                        });
                    }
                }
                // console.log("Versionladen hat nicht gklappt:" + vdata.webVersion + vdata.AllVersion);
            }
            return { webVersion: ALLVersion.version, warVersion: vdata.warVersion, appVersion: appVersionServer };
        }
    } catch (ex) {
        console.log("Versionladen hat nicht gklappt", ex);

        if (currentPlattform !== "web") {
            checkOldAppVersion(currentPlattform);
        }
        // showSnackBar({ text: "ups: " + ex, color: "error", duration: -1 });
    } finally {
        if (versionCallback) { versionCallback(); }
    }
    return { webVersion: ALLVersion.version, warVersion: "UNKNOWN", appVersion: "UNKNOWN" };
}

function checkOldAppVersion(currentPlattform: string) {
    let appVersion: any; // grauslich aber egal
    let error: any;
    App.getInfo().then(i => {
        appVersion = i?.version ?? "2.0.fallback";
    }).catch(err => {
        error = err;
        console.log("error:" + err);
    })
    .finally(() => {
        if (parseInt( appVersion.substring(0, 1), 10) <= 2) {
            const text = "Es ist eine zwingende neue AppVersion verfügbar! Bitte gehen Sie in den AppStore und aktualisiern Sie diese!";
            alert(text);
            if (currentPlattform === "ios") {
                window.open("https://apps.apple.com/at/app/all-unlimited/id1555265479");
            } else {
                window.open("https://play.google.com/store/apps/details?id=com.dvh.allunlimited");
            }
        }
    });
}

