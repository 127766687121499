import { Capacitor } from "@capacitor/core";
import { AppUpdatePlugin, ShouldFallbackToFrameResult } from "@/plugins/AppUpdatePlugin";

import AUApi from "./api";
import Constants from "./Constants";
import { Keyboard } from "@capacitor/keyboard";
import { HttpHelperPlugin } from "./plugins/HttpHelperPlugin";
import DeviceInfo from "./DeviceInfoPlugin";
import { FileHelperPlugin } from "./plugins/FileHelperPlugin";
import { AzureOAuthPlugin } from "./plugins/AzureOAuthPlugin";
import { NativeBiometric } from "@capgo/capacitor-native-biometric";
// import { BarcodeScanner } from "@capacitor-community/barcode-scanner";


const CapacitorUrlPart = "/_capacitor_file_/";
const maxFallbackMajorVersion = 2; // all 2.xy versions and before

function mapToLocalUrl(url: string) {
    url = Capacitor.convertFileSrc(url);
    url = url.replace("file:/", CapacitorUrlPart);
    return url;
}

let updateRedirectPending = false;
export async function checkNativeUpdateAvailable() {
    const plattform = Capacitor.getPlatform();
    if (AppUpdatePlugin) {
        if (window.location.href.indexOf(CapacitorUrlPart) >= 0) {
            console.log("already running in local copy...");
            return false;
        }

        const result = await AppUpdatePlugin.checkNativeCopyAvailable();
        console.log('result of appPlugin' + JSON.stringify(result));

        if (!result.available || !result.info || !result.index) {
            console.log("no local copy available");
            return false;
        }
        const index = mapToLocalUrl(result.index);
        console.log("navigating to local copy...", result);
        Constants.indexFile = index;
        updateRedirectPending = true;
        return true;
    }
    return false;
}

export async function downloadNativeUpdate(downloadUrl: string) {
    if (Capacitor.getPlatform() !== "web") {
        console.log("checking native update");
        const result =
            await AppUpdatePlugin.downloadAndInstallUpdate(
                {
                    message: "update wird heruntergeladen!",
                    downloadUrl,
                    config: "var ALLConfig = " + JSON.stringify(AUApi.Current.getCurrentConfig()) + ";"
                });

        if (!result.available || !result.info || !result.index) {
            console.log("no update available");
            return;
        }
    }
}

// registers unbefore handler which triggers a dialog of the browser to check if the user really wants to leave
// if the app itself wants to reload window.reloadingApp is set to prevent the dialog.
export function initAppUnloadHandler() {
    (document.body as any).onbeforeunload = (e: any) => {
        if (!((window as any).reloadingApp) && AUApi.Current.user && !AUApi.Current.getCurrentConfig().debug) {
            e.preventDefault();
            return e.returnValue = "do you really want to leave?";
        }
    };

    (document.body as any).onunload = (e: any) => {
        if (!((window as any).reloadingApp) && !AUApi.Current.getCurrentConfig().debug) {
            // AUApi.Current.logout(); // perhaps telling the server we are out...
            AUApi.Current.logoutByBeacon();
            AUApi.Current.eraseLocalData(); // killing me hardly
        }
    };
}

export function reloadApp(eraseLocalData: boolean = false) {
    if (eraseLocalData) {
        AUApi.Current.eraseLocalData();
    }
    // path suche da sonst subverzeichnisse nicht klappen!
    let rootUrl = window.origin;
    if (rootUrl !== "http://localhost:8080") {
        rootUrl = AUApi.Current.nativeRoot;
    }

    // if (configPath !== "/ALL/UNLIMITED/") {
    //     pathALL = configPath;
    // }
    (window as any).reloadingApp = true;
    if (window.location.href !== rootUrl) {
        window.location.href = rootUrl;
    } else {
        window.location.reload();
    }
}

export function navigateToNewVersion() {
    console.log("native update available - getting redirected");

    AppUpdatePlugin.shouldFallbackToFrame(maxFallbackMajorVersion).then((t: ShouldFallbackToFrameResult) => {
        console.log("fallback to frame: " + t.shouldFallback);
        debugger;
        if (!t.shouldFallback) {
            document.body.innerHTML = "<h1> you are getting redirected to an updated version...</h1>";
            setTimeout(() => window.location.reload(), 1000);
        } else {
            // kanns nicht - hin zum alten :)
            createLegacyFrame();
        }
    }).catch(ex => {
        // not implemented -> hin zum alten
        createLegacyFrame();
    });
}


/* old Frameset: */

let iframe: HTMLIFrameElement;
let oldX = 0;
let oldY = 0;
function resizeFrame() {
    if (!iframe || window.innerWidth === oldX && window.innerHeight === oldY) { return; }

    oldX = window.innerWidth;
    oldY = window.innerHeight;

    console.log("setting frame size and style...");

    iframe.style.width = "100%"; // window.innerWidth + "px";
    // iframe.style.height = "100%";
    if (!Constants.PlatformAndroid) {
        iframe.style.height = (window.innerHeight - 25) + "px"; // bei - 25 zu hoher Abstand unten...
    } else {
        iframe.style.height = window.innerHeight + "px";
    }
    iframe.style.margin = "0px";
    // iframe.style.border = "3px solid green"; // grüner Rahmen bei der App weg!
    // alert("resized " + window.innerWidth + "x" + window.innerHeight);
    console.log("resize - height:" + iframe.style.height);

    setTimeout(resizeFrame, 500);
}

function createLegacyFrame() {
    const frame = iframe = document.createElement("iframe");

    frame.src = Constants.indexFile;

    // frame.onloadstart = (ev) => {
    //     console.log("loaded iframe onloadstart", ev);
    //     debugger;
    // }
    resizeFrame();
    const appdiv = document.getElementById("app");
    appdiv?.append(frame);

    setTimeout(() => {
        console.log("loaded iframe onloadstart");

        const contentWindow = (frame.contentWindow as any);
        const myWindow = (window as any);
        // debugger;
        contentWindow.androidBridge = myWindow.androidBridge;
        contentWindow.Capacitor = Capacitor;
        contentWindow.CapacitorWebFetch = myWindow.CapacitorWebFetch;
        contentWindow.CapacitorHttpAndroidInterface = myWindow.CapacitorHttpAndroidInterface;
        contentWindow.CapacitorPlatforms = myWindow.CapacitorPlatforms;
        contentWindow.Cordova = (window as any).Cordova;
        contentWindow.cordova = (window as any).cordova;
        Capacitor.Plugins.HttpHelper = HttpHelperPlugin;
        Capacitor.Plugins.HttpHelperPlugin = HttpHelperPlugin;
        // contentWindow.Capacitor.Plugins = Object.assign(
        //     contentWindow.Capacitor.Plugins,
        //     {
        //         AppUpdatePlugin, HttpHelperPlugin, AllDeviceInfo: DeviceInfo,
        //         AzureOAuthPlugin, NativeBiometric,
        //         BarcodeScanner: {
        //             checkPermission() { return Promise.resolve(true); },
        //             prepare() { /* todo */ },
        //             showBackground() { /* todo */ },
        //             hideBackground() { /* todo */ },
        //             startScan() { /* todo */ },
        //             disableTorch() { /* todo */ },
        //             toggleTorch() { /* todo */ },
        //             stopScan() { /* todo */ },
        //         },
        //         FileHelperPlugin
        //     }
        // );
        contentWindow.reloadApp = () => window.location.reload();
        contentWindow.resizeFrame = () => resizeFrame;
        window.addEventListener("resize", resizeFrame, { passive: true });
    }, 100);

    Keyboard.addListener("keyboardDidShow", resizeFrame);
    Keyboard.addListener("keyboardDidHide", resizeFrame);

    resizeFrame();
}

